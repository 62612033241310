<template>
	<div>
		<lined-textarea @input="handleInput"
		                v-model="text"
		                :nowrap="false"
		                :disabled="false"
                        :onlyNumberAndEnter="onlyNumberAndEnter"
		                :styles="{ height: this.height, minHeight: '60px', resize: 'height' }"
        />
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"/>

	</div>
</template>
<script>
	import LinedTextarea from "@/components/elements/LinedTextarea";

	export default {
		components: {
			LinedTextarea
		},
		props: {
			value: {
				type: String,
				default: ''
			},
			validateError: {
				type: String,
				default: ''
			},
			height: {
				type: String,
				default: '100px'
			},
            onlyNumberAndEnter: {
                type: Boolean,
                default:false
            }
		},
		watch: {
			value: function (newValue) {
				this.setSelect(newValue)
			}
		},
		created() {
			this.setSelect(this.value)
		},
		data() {
			return {
				text: '',
			}
		},
		methods: {
			setSelect(value) {
				if (value) {
					this.text = value.split(',').map(item => item.trim()).join('\n')
				}
				else this.text = '';
			},
			handleInput(event) {
				if (event) {
					this.$emit('input', event.replaceAll(',', '').split('\n').map(item => item.trim()).join(','))
				}
				else {
					this.$emit('input', null)
				}
			}
		}
	};
</script>
